<template>
  <div v-if="publishedImage.url" @click="recordClick">
    <a :href="publishedImage.clickUrl" target="new"
      ><img :src="publishedImage.url" style="border-radius: 10px"
    /></a>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  data() {
    return {
      publishedImage: {},
    };
  },
  computed: {
    ...mapGetters(['activityDetails']),
  },
  async created() {
    const options = {
      timeout: 30000,
    };
    try {
      // Allow forcing of marketing image for preview in portal
      const uri = window.location.search.substring(1);
      const params = new URLSearchParams(uri);
      const marketingPreviewUrl = params.get('marketingPreviewUrl');
      const clickUrl = params.get('clickUrl');
      if (marketingPreviewUrl) {
        this.publishedImage = {
          url: marketingPreviewUrl,
        };
        if (clickUrl) {
          this.publishedImage.clickUrl = clickUrl;
        }
        this.$emit('image-loaded', true);
        return;
      } else {
        let url = `${this.$omwConfig.marketing.retrievalUrl}/${this.$omwConfig.marketing.customer}`;
        if (this.$omwConfig.marketing.demo) {
          url += `?categories=${encodeURIComponent(
            this.$omwConfig.marketing.demoCustomer,
          )}`;
        } else if (this.activityDetails[this.$omwConfig.marketing.fieldName]) {
          url += `?categories=${encodeURIComponent(
            this.activityDetails[this.$omwConfig.marketing.fieldName],
          )}`;
        }
        const { data } = await axios.get(url, options);
        this.publishedImage = data;
        if (this.publishedImage) {
          this.$emit('image-loaded', true);
        }
      }
    } catch (err) {
      console.error('error fetching data', err);
    }
  },
  methods: {
    async recordClick() {
      const clickSubmissionData = {
        apptNumber: this.activityDetails.apptNumber,
        imageId: this.publishedImage.id,
        campaignName: this.publishedImage.name,
        clickedDate: new Date(),
      };
      const axiosConfig = {
        url: `${this.$omwConfig.marketing.recordClickUrl}/${this.$omwConfig.marketing.customer}`,
        method: 'post',
        data: clickSubmissionData,
      };
      try {
        await axios(axiosConfig);
      } catch (err) {
        console.error(err);
      }
    },
  },
});
</script>
